import React from 'react';
import { useRouter } from 'next/router';
import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';

// See corresponding unit test for all the test cases
function Link(
    props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
) {
    const router = useRouter();
    let href = props.href || '';

    // trim trailing slash for non-root urls
    if (href.length > 1 && href.endsWith('/')) {
        href = href.substr(0, href.length - 1);
    }

    if (!href.startsWith('http')) {
        // If link doesn't start with http, then convert to local link
        if (!(href.startsWith('/') || href === '#')) {
            href = `/${href}`;
        }

        // 1. If it's a local link
        // 2. There is a language in the url
        // 3. There is no language in the link
        // Then add it
        const language = getLanguageFromUrl(router ? router.asPath : '');
        if (language && !startsWithSupportedLanguage(href)) {
            href = `/${language}${href}`;
        }
    }

    return <a {...props} href={href} onClick={props.onClick} />;
}

export default Link;

export function getLanguageFromUrl(urlStr: string) {
    // Filter out empty and dynamic routes
    const split = urlStr
        .split('/')
        .filter((path) => path !== '')
        .filter((path) => !/\[.+?\]/.test(path));

    // extract language
    const splitPart = split.shift();
    if (splitPart && supportedLanguageIds.includes(splitPart as SupportedLanguage)) {
        return splitPart as SupportedLanguage;
    }

    return null;
}

function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguageIds.some((language) => url.startsWith(`/${language}/`));
}

import * as React from 'react';
import cx from 'classnames';
import Link from 'components/link/link';

enum ButtonSize {
    SMALL,
    MEDIUM,
    LARGE,
    STRETCH,
}

enum ButtonDesignType {
    NONE,
    FILLED,
    STROKE,
    TRANSPARENT,
    SEMITRANSPARENT,
    MONOCHROME,
    LINK,
    READ_MORE,
}

export const mapDesignTypeToClassname: { [key in ButtonDesignType]: string } = {
    [ButtonDesignType.NONE]: '',
    [ButtonDesignType.FILLED]: 'button--filled',
    [ButtonDesignType.STROKE]: 'button--stroke',
    [ButtonDesignType.TRANSPARENT]: 'button--transparent',
    [ButtonDesignType.SEMITRANSPARENT]: 'button--semitransparent',
    [ButtonDesignType.MONOCHROME]: 'button--monochrome',
    [ButtonDesignType.LINK]: 'button--link',
    [ButtonDesignType.READ_MORE]: 'button--cta-read-more',
};

const mapSizeToClassname: { [key in ButtonSize]: string } = {
    [ButtonSize.SMALL]: 'button--sm',
    [ButtonSize.MEDIUM]: '',
    [ButtonSize.LARGE]: 'button--lg',
    [ButtonSize.STRETCH]: 'button--stretch',
};

enum ButtonColor {
    NONE,
    Primary,
    White,
    Dark,
}

const mapColorToClassname = {
    [ButtonColor.NONE]: '',
    [ButtonColor.Primary]: 'button--primary',
    [ButtonColor.White]: 'button--white',
    [ButtonColor.Dark]: 'button--color-dark',
};

type ButtonType = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>['type'];

type ButtonProps = {
    icon?: React.ReactNode;
    text?: string | React.ReactNode;
    labelText?: string | React.ReactNode;
    href?: string;
    hasExtraVgap?: boolean;
    extraClass?: string;
    jsClass?: string;
    color?: ButtonColor;
    size?: ButtonSize;
    designType?: ButtonDesignType;
    isCircle?: boolean;
    inline?: boolean;
    target?: string;
    rel?: string;
    type?: ButtonType;
    isApp?: boolean;
    onClick?: (event?: any) => void;
    seo?: {
        name?: string;
        category?: string;
        action?: string;
        label?: string;
        source?: string;
    };
};

function Button(props: ButtonProps) {
    const {
        icon,
        labelText,
        text,
        href,
        hasExtraVgap,
        extraClass,
        jsClass,
        color = ButtonColor.NONE,
        size = ButtonSize.MEDIUM,
        designType = ButtonDesignType.NONE,
        isCircle,
        inline,
        isApp,
        onClick,
        target,
        rel,
        type,
        seo = {
            name: null,
            category: null,
            action: null,
            label: null,
            source: null,
        },
    } = props;

    const className = cx({
        button: true,
        'button--circle': isCircle,
        'button--app': isApp,
        'button--extra-vgap': hasExtraVgap,
        'button--inline': inline,
        [mapColorToClassname[color]]: true,
        [mapSizeToClassname[size]]: true,
        [mapDesignTypeToClassname[designType]]: true,
        [jsClass as string]: jsClass,
        [extraClass as string]: extraClass,
    });

    /**
     * https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
     */
    let buttonRel = rel;

    if (target === '_blank') {
        buttonRel = 'noreferrer';
    }

    if (href) {
        return (
            <Link
                aria-label={labelText?.toString()}
                className={className}
                href={href}
                target={target}
                rel={buttonRel}
                type={type}
                data-seo-name={seo.name}
                data-seo-category={seo.category}
                data-seo-action={seo.action}
                data-seo-label={seo.label}
                data-source={seo.source}
                data-vars-seo-name={seo.name}
                data-vars-seo-category={seo.category}
                data-vars-seo-action={seo.action}
                data-vars-seo-label={seo.label}
                data-vars-source={seo.source}
                onClick={onClick}
            >
                <span className="button__content">
                    {icon ? <span className="button__icon">{icon}</span> : null}
                    {text ? <span className="button__text">{text}</span> : null}
                </span>
            </Link>
        );
    }

    return (
        <button
            aria-label={labelText?.toString()}
            className={className}
            type={type}
            data-seo-name={seo.name}
            data-seo-category={seo.category}
            data-seo-action={seo.action}
            data-seo-label={seo.label}
            data-source={seo.source}
            onClick={onClick}
        >
            <span className="button__content">
                {icon ? <span className="button__icon">{icon}</span> : null}
                {text ? <span className="button__text">{text}</span> : null}
            </span>
        </button>
    );
}
Button.color = ButtonColor;
Button.size = ButtonSize;
Button.designType = ButtonDesignType;

export default Button;

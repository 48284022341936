import React, { useEffect, useState } from 'react';
import Icon from 'components/icon/icon';
import throttle from 'lib/throttle';

const GoToTopButton = () => {
    const [scrollTopAvailable, setScrollTopAvailable] = useState(false);
    const onClickHandler = () => {
        global.window.scrollTo(0, 0);
    };

    const updateScrollProgress = () => {
        const windowHeight = global.window.innerHeight;
        const winScroll =
            global.document.body.scrollTop || global.document.documentElement.scrollTop;

        setScrollTopAvailable(winScroll > windowHeight);
    };

    useEffect(() => {
        updateScrollProgress();

        global.window.addEventListener('scroll', throttle(updateScrollProgress, 300), {
            passive: true,
        });

        return () => {
            global.window.removeEventListener('scroll', throttle(updateScrollProgress, 300));
        };
    }, []);

    return (
        <button
            className="go-to-top-button"
            aria-label="scroll to the top"
            style={{
                opacity: scrollTopAvailable ? 1 : 0,
                pointerEvents: scrollTopAvailable ? 'all' : 'none',
            }}
            onClick={onClickHandler}
        >
            <Icon name={Icon.Name.GenericChevronUp} />
        </button>
    );
};

export default GoToTopButton;
